<template>
  <div
    class="jumbotron"
    :class="{ 'has-bgimage': backgroundImage }"
    :style="`--backgroundImage: url(${backgroundImage});--mobileBackgroundImage: url(${mobileBackgroundImage});--background: ${background}; --textColor: ${textColor}; --titleColor: ${titleColor}; --mobileHeight: ${mobileHeight}px`"
  >
    <div
      v-if="isMobileView && (imgBackgroundColor || mobileImgSrc)"
      class="mobile-chart-row illustration-background container"
      style="height: 345px"
      :style="`--img-background-color: ${imgBackgroundColor};`"
    >
      <div class="row" style="height: 100%">
        <img :src="mobileImgSrc" class="mx-auto my-auto" />
      </div>
    </div>
    <div class="container">
      <div class="top-image-wrapper d-flex justify-content-center">
        <slot name="top-image"></slot>
      </div>
    </div>
    <div class="container jumbotron-container">
      <div class="row jumbotron-row">
        <div class="col-12 col-md-6">
          <div class="content-wrapper">
            <div
              class="top-section"
              :class="
                storesLabel ? '' : 'd-flex align-content-center flex-wrap pt-0'
              "
            >
              <img v-if="logoSrc" :src="logoSrc" class="logo" />
              <h1 v-if="title" class="title headline-l" v-html="title"></h1>
              <span class="description text-l" v-html="description" />
              <base-button
                v-if="buttonText"
                class="jumbotron-button"
                :text="buttonText"
                :size="'large'"
                @clicked="handleButtonClick"
              />
            </div>
            <div v-if="socials">
              <div class="icons">
                <a href="https://www.facebook.com/blockbenofficial">
                  <i class="bb-facebook icon" />
                </a>
                <a href="https://twitter.com/blockben?lang=en">
                  <img src="/images/icons/x-purple.svg" class="icon"/>
                </a>
                <a href="https://www.linkedin.com/company/blockben/">
                  <i class="bb-linkedin icon" />
                </a>
                <a href="https://www.youtube.com/channel/UC9SB-B1Gf50SVnerTLGIMyA">
                  <i class="bb-youtube icon" />
                </a>
                <a href="https://t.me/BlockBen_Official">
                  <img src="/images/icons/telegram.svg" class="icon"/>
                </a>
              </div>
            </div>
            <div
              v-if="imageAboveStoreImg && isMobileView"
              class="illustration-above-store-img"
            >
              <img :src="imageAboveStoreImg" class="mx-auto" />
            </div>
            <div v-if="storesLabel" class="bottom-section">
              <span class="stores-label text-l">{{ storesLabel }}</span>
              <div class="stores">
                <store-button :version="'apple'" />
                <store-button :version="'google'" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isMobileView"
          class="col-md-6 h-100"
          style="display: flex; align-items: center"
        >
          <div v-if="hasChart" class="chart-wrapper">
            <slot name="chart"></slot>
          </div>
          <div
            v-if="imgBackgroundColor"
            :style="` --img-background-color: ${imgBackgroundColor};`"
            class="illustration-background"
          ></div>
          <div
            v-if="imgSrc"
            class="illustration d-none d-md-block"
            style="margin-left: 100px"
          >
            <img :src="imgSrc" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobileView" class="mobile-chart-row">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
              v-if="hasChart"
              class="chart-wrapper d-flex justify-content-center"
            >
              <slot name="chart"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Jumbotron",
  props: {
    title: {
      type: String,
      required: false,
    },
    titleColor: {
      type: String,
      required: false,
      default: "#4B2EAA",
    },
    logoSrc: {
      type: String,
      required: false,
    },
    imgSrc: {
      type: String,
      required: false,
    },
    mobileImgSrc: {
      type: String,
    },
    imageAboveStoreImg: {
      type: String,
    },
    imgBackgroundColor: {
      type: String,
    },
    description: {
      type: String,
      required: false,
    },
    socials: {
      type: Boolean,
      required: false,
    },
    storesLabel: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    background: {
      type: String,
      required: false,
      default: "#fff",
    },
    backgroundImage: {
      type: String,
      required: false,
    },
    mobileBackgroundImage: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      required: false,
      default: "#46668F",
    },
    mobileHeight: {
      type: [Number, String],
      required: false,
      default: 635,
    },
  },
  components: {},
  data: () => ({
    isMobileView: false,
  }),
  created() {
    this.setMobileView();

    window.addEventListener("resize", this.setMobileView);
  },
  computed: {
    hasChart() {
      return this.$slots && !!this.$slots.chart;
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit("buttonClicked");
    },
    setMobileView() {
      if (window.innerWidth <= 767.98) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setMobileView);
  },
};
</script>

<style lang="scss" scoped>
.jumbotron-row {
  @media (max-width: $breakpoint-xs) {
    height: unset !important;
  }
}
.jumbotron {
  background: var(--background);
  padding: 0;
  border-radius: 0;
  margin: 0;

  &.has-bgimage {
    background: var(--backgroundImage);

    @media (max-width: $breakpoint-xs) {
      background: var(--mobileBackgroundImage);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    background-color: var(--background);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &-container {
    height: 705px;

    @media (max-width: $breakpoint-sm) {
      height: auto;
      margin-bottom: 30px;
    }
  }

  &-row {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.logo {
  max-height: 110px;

  @media (max-width: $breakpoint-md) {
    max-width: 160px;
  }
}

.content-wrapper {
  height: 100%;
  display: grid;

  .top-section {
    padding: 75px 45px 0 0;

    @media (max-width: $breakpoint-md) {
      padding-right: 0;
    }

    .title {
      color: var(--titleColor);
    }

    .jumbotron-button {
      margin: 65px 0 0;
    }
  }

  .bottom-section {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
  }
}

.description {
  color: var(--textColor);
  margin: 40px 0 0;
  display: block;
}

.stores-label {
  color: var(--textColor);
  margin: 30px 0 20px;
  display: block;
  font-family: "HKNova-SemiBold", sans-serif;
}

.stores {
  display: flex;
  align-items: center;

  .store-button {
    &:first-child {
      margin-right: 20px;
    }
  }
}

.illustration-background {
  width: calc(50vw - 23px);
  height: 100%;
  position: absolute;
  background: var(--img-background-color);
  z-index: 1;
  border-radius: 400px 0px 0px 0px;

  @media (max-width: $breakpoint-xs) {
    width: 100%;
    height: 345px;
    border-radius: 0px;
    position: relative;
    z-index: unset;
  }
}

.illustration {
  z-index: 1;

  @media (max-width: $breakpoint-xs) {
    position: absolute;
    width: 100%;
    height: 70%;
    display: inline-block;
    text-align: center;
    margin: auto;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);

    & img {
      height: 100%;
    }
  }
}

.chart-wrapper {
  margin-left: auto;
}

.mobile-chart-row {
  .container {
    @media (max-width: $breakpoint-xs) {
      padding: 0;
    }
  }

  .row {
    @media (max-width: $breakpoint-xs) {
      margin: 0;
    }
  }

  .col-12 {
    @media (max-width: $breakpoint-xs) {
      padding: 0;
    }
  }

  .chart-wrapper {
    padding: 20px 0;

    @media (max-width: $breakpoint-xs) {
      padding: 0;
    }
  }
}

.icons {
  margin-top: 32px;
  line-height: $text-m;
  display: flex;
  align-items: center;

  @media (max-width: $breakpoint-xs) {
    width: 100%;
    justify-content: space-around;
  }
}

.icon {
  color: rgba(75, 46, 170, 1);
  font-size: 20px;
  margin-right: 50px;

  @media (max-width: $breakpoint-md) {
    margin-right: 30px;
  }

  @media (max-width: $breakpoint-xs) {
    margin-right: 0px;
  }
}

</style>
